import { authOptions } from "@/auth";
import { isNil } from "lodash";
import { getServerSession } from "next-auth";
import { getSession } from "next-auth/react";
import { toast } from "sonner";
import ky from "ky";
import { timeout } from "@/lib/utils/general";

interface RequestOptions {
  method?: string;
  headers?: Record<string, string>;
  body?: any;
  stringifyBody?: boolean;
  queryParams?: Record<string, any>;
  timeoutTime?: number;
}

interface ResponseOptions {
  responseType?: "json" | "arrayBuffer" | "untouched";
  throwIfError?: boolean;
  dontHandleResponse?: boolean;
}

const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_DOMAIN as string;

const customFetch = async (
  url: string,
  requestOptions: RequestOptions = {},
  apiURL = BACKEND_URL,
  responseOptions: ResponseOptions = { responseType: "json", throwIfError: true, dontHandleResponse: false },
): Promise<any> => {
  let session = await getSession();
  let isServer = false;
  console.debug(`client session: `, session);
  if (!session) {
    session = await getServerSession(authOptions);
    isServer = true;
    console.debug(`server session: `, session);
  }

  const { method = "GET", headers = {}, body, stringifyBody = true, queryParams = {}, timeoutTime } = requestOptions;

  // if (session) {
  // headers.Authorization = `Bearer ${session.token.accessToken}`;

  if (headers["Content-Type"] === undefined) {
    headers["Content-Type"] = "application/json";
  }
  else if (headers["Content-Type"] === "multipart/form-data") {
    delete headers["Content-Type"];
  }
  // }

  const queryParamsString = Object.keys(queryParams).reduce((prev, cur) => {
    const prefix = prev.length === 0 ? "?" : "&";
    if (isNil(queryParams[cur])) {
      return prev;
    }
    // separate case for string needed first, as strings are also iterables but we handle them as a whole
    else if (typeof queryParams[cur] === "string") {
      return `${prev}${prefix}${cur}=${queryParams[cur]}`;
    }
    else if (typeof queryParams[cur][Symbol.iterator] === "function") {
      const list: any[] = queryParams[cur];
      if (list.length === 0) {
        return prev;
      }
      return `${prev}${prefix}${cur}[]=${list.join(`&${cur}[]=`)}`;
    }
    else {
      return `${prev}${prefix}${cur}=${queryParams[cur].toString()}`;
    }
  }, "");

  console.debug(`full url: `, `${apiURL}${url}${queryParamsString}`);

  try {
    // const response = await fetch(`${apiURL}${url}${queryParamsString}`, {
    //   method,
    //   headers,
    //   body: stringifyBody ? JSON.stringify(body) : body,
    // });

    const response = await ky(`${apiURL}${url}${queryParamsString}`, {
      method,
      headers,
      body: stringifyBody ? JSON.stringify(body) : body,
      retry: { limit: process.env.NODE_ENV === "development" ? 5 : 0, statusCodes: [401] },
      hooks: {
        beforeRequest: [
          (request) => {
            if (session) {
              request.headers.set("Authorization", `Bearer ${session.token.accessToken}`);
            }
          },
        ],
        beforeRetry: [
          async ({ request, options, error, retryCount }) => {
            if (process.env.NODE_ENV === "development") {
              console.log(`on development, 401 possibly due to out of sync server time (looking at you, WSL)`);
              console.log(`delay 1s before retry #${retryCount}`);
              console.log(`options: `, options);
              console.log(`request: `, request);
              console.log(`error: `, error);
              await timeout(1000);
            }
          },
        ],
      },
      timeout: timeoutTime,
    });

    // console.log(`return fetch response for ${url}: `, response);

    if (responseOptions.dontHandleResponse) {
      return response;
    }

    if (!response.ok || response.status === 401) {
      const errorObject = (await response.json()) as { message: string };
      if (errorObject.message === "Please login first") {
        throw new Error(`Authentication timed out. Please refresh the page.`);
      }
      throw new Error(`Request failed with status ${response.status}`);
    }

    if (responseOptions.responseType === "json") {
      const data = await response.json();
      // console.log(`return fetch data for ${url}: `, data);
      return data;
    }
    else if (responseOptions.responseType === "arrayBuffer") {
      const data = await response.arrayBuffer();
      return data;
    }
    else {
      return response;
    }
  }
  catch (error: unknown) {
    console.error("An error occurred:", error);
    if (!isServer && error instanceof Error) {
      // toast.error(`An error occurred: ${error.message}`);
      toast.error(error.message);
    }

    if (responseOptions.throwIfError) {
      throw error;
    }
    else {
      return error;
    }
  }
};

export default customFetch;
