"use client";

import * as PopoverPrimitive from "@radix-ui/react-popover";
import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps } from "react";
import { Cross2Icon } from "@radix-ui/react-icons";
import { ClassValue } from "clsx";
const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverAnchor = PopoverPrimitive.Anchor;
interface CustomPopoverProps extends ComponentProps<typeof PopoverPrimitive.Content> {
  disableArrow?: boolean;
  showCloseButton?: boolean;
  arrowClassName?: ClassValue;
  popoverElement?: HTMLElement;
  usePortal?: boolean;
}
const PopoverContent = ({
  popoverElement,
  usePortal = true,
  ...props
}: CustomPopoverProps) => {
  return usePortal ? <PopoverPrimitive.Portal container={popoverElement} data-sentry-element="unknown" data-sentry-component="PopoverContent" data-sentry-source-file="popover.tsx">
      <PopoverInnerContent {...props} data-sentry-element="PopoverInnerContent" data-sentry-source-file="popover.tsx" />
    </PopoverPrimitive.Portal> : <PopoverInnerContent {...props} data-sentry-element="PopoverInnerContent" data-sentry-component="PopoverContent" data-sentry-source-file="popover.tsx" />;
};
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
const PopoverInnerContent = ({
  className,
  children,
  align = "center",
  sideOffset = 4,
  disableArrow = false,
  showCloseButton = false,
  arrowClassName,
  ...props
}: CustomPopoverProps) => {
  return <PopoverPrimitive.Content align={align} sideOffset={sideOffset} className={cn("text-popover-foreground z-[400] rounded-md border bg-primary p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", className)} {...props} data-sentry-element="unknown" data-sentry-component="PopoverInnerContent" data-sentry-source-file="popover.tsx">
      {children}
      {!disableArrow && <PopoverPrimitive.Arrow className={cn(arrowClassName)} />}
      {/* Show close button won't work if the popover open/close is being controled by state. You may need to implement that feature into here if needed. */}
      {showCloseButton && <PopoverPrimitive.Close className="absolute right-[5px] top-[5px] inline-flex size-[25px] cursor-default items-center justify-center rounded-full text-gray-800 outline-none hover:bg-gray-200 focus:shadow-[0_0_0_2px] focus:shadow-gray-400" aria-label="Close">
          <Cross2Icon />
        </PopoverPrimitive.Close>}
    </PopoverPrimitive.Content>;
};
export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };