import { useQuery } from "@tanstack/react-query";
import { cn } from "@/lib/utils/cssUtils";
import { isEmpty, isNil } from "lodash";
import { Card as SCNCard, CardContent } from "@/components/ui/card";
import { useMemo, useState } from "react";
import pharamcyAPI from "@/api/pharmacyAPI";
import { Pharmacy } from "@/lib/types/pharmacy";
import { userProvinceMap } from "@/lib/types/constants";
import { DrawingPinFilledIcon } from "@radix-ui/react-icons";
import { ClassValue } from "clsx";
import { FuzzyCombobox, FuzzyComboboxItem } from "@/components/ui/fuzzyCombobox";
import { Skeleton } from "@/components/ui/skeleton";
import { selectedPatientAtom } from "@/lib/states/globalStates";
import { useAtomValue } from "jotai";
export interface PharmacyComboItem extends FuzzyComboboxItem {
  otherData: Pharmacy;
}
const getPharmacyLabel = (pharmacy: Pharmacy) => {
  let label = "";
  if (pharmacy.name) {
    label += pharmacy.name;
  }
  if (pharmacy.city) {
    label += ` | ${pharmacy.city}`;
  }
  if (pharmacy.fax) {
    label += ` | F: ${pharmacy.fax}`;
  }
  return {
    label,
    value: pharmacy.id
  };
};

// TODO: do these generically in generalized utliity function
export const getPharmacyAddress = (pharmacy: Pharmacy) => {
  const streetAddress = pharmacy.streetAddress ? pharmacy.streetAddress : "...";
  const city = pharmacy.city ? pharmacy.city : "...";
  const province = pharmacy.province ? userProvinceMap[pharmacy.province] : "...";
  const postal = pharmacy.postal ? pharmacy.postal : "...";
  return `${streetAddress}, ${city} ${province}, ${postal}`;
};
interface PharmacyDropdownProps {
  pharmacyId?: number | null;
  onSelectPharmacy: (pharmacy: Pharmacy | null) => void;
  containerClassName?: ClassValue;
  popoverTriggerClassName?: ClassValue;
  popoverContentClassName?: ClassValue;
  popoverContentItemClassName?: ClassValue;
  popoverContentSelectedItemClassName?: ClassValue;
  popoverContentSelectedItemTextClassName?: ClassValue;
  popoverContentAlignment?: "start" | "center" | "end";
  placeholder?: string;
  popoverContentUsePortal?: boolean;
  allowRemove?: boolean;
  showDisplayValue?: boolean;
  disabled?: boolean;
}
export const PharmacyDropdown = ({
  pharmacyId,
  onSelectPharmacy,
  containerClassName,
  popoverTriggerClassName,
  popoverContentItemClassName,
  popoverContentClassName,
  popoverContentSelectedItemClassName,
  popoverContentSelectedItemTextClassName,
  popoverContentAlignment = "start",
  placeholder = "Select a pharmacy",
  popoverContentUsePortal = true,
  allowRemove = true,
  showDisplayValue = true,
  disabled
}: PharmacyDropdownProps) => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const selectedPharmacyQueryObject = usePharmacyQueryObject({
    pharmacyId
  });
  const searchPharmaciesQueryObject = useQuery({
    queryKey: ["pharmacies", "search", searchTerm],
    queryFn: () => pharamcyAPI.getPharmaciesElastic(searchTerm!),
    select: data => {
      return data.map(pharmacy => ({
        name: <PharmacyCard pharmacy={pharmacy} />,
        value: String(pharmacy.id),
        displayValue: getPharmacyLabel(pharmacy).label,
        otherData: {
          ...pharmacy,
          itemType: pharmacy.status // I dont know why you would ever do this, but old code does this and if I question it I might not like the answer...
        } satisfies Pharmacy as Pharmacy
      })) satisfies PharmacyComboItem[] as PharmacyComboItem[];
    },
    enabled: !isEmpty(searchTerm ?? ""),
    staleTime: 0
  });
  const pinnedPharmaciesQueryObject = useQuery({
    queryKey: ["pharmacies", "favorites"],
    queryFn: async () => {
      // Pins 2 requested pharmacies when the search text is blank or no search has been performed
      // TODO: remove when pharmacy favourites feature is implemented

      const pinnedId1 = 1932; // ID is the same across prod and dev
      let pinnedId2 = 0;
      if (process.env.NEXT_PUBLIC_ENV === "prod") {
        pinnedId2 = 4941;
      } else if (process.env.NEXT_PUBLIC_ENV === "dev") {
        pinnedId2 = 4923;
      }
      const [p1, p2] = await Promise.all([pharamcyAPI.getPharmacyById(pinnedId1), pharamcyAPI.getPharmacyById(pinnedId2)]);
      return [{
        ...p1,
        pinned: true
      }, {
        ...p2,
        pinned: true
      }] satisfies Pharmacy[] as Pharmacy[];
    },
    select: data => {
      return data.map(pharmacy => ({
        name: <PharmacyCard pharmacy={pharmacy} showPinIconOnPinned />,
        value: String(pharmacy.id),
        displayValue: getPharmacyLabel(pharmacy).label,
        otherData: {
          ...pharmacy,
          itemType: pharmacy.status // I dont know why you would ever do this, but old code does this and if I question it I might not like the answer...
        } satisfies Pharmacy as Pharmacy
      })) satisfies PharmacyComboItem[] as PharmacyComboItem[];
    },
    staleTime: 60 * 1000
  });
  const consolidatedData = useMemo(() => isEmpty(searchTerm ?? "") ? [...(pinnedPharmaciesQueryObject.data ?? []), ...(isNil(selectedPharmacyQueryObject.data) || isNil(pharmacyId) ? [] : [selectedPharmacyQueryObject.data])] : [...(isNil(selectedPharmacyQueryObject.data) || isNil(pharmacyId) ? [] : [selectedPharmacyQueryObject.data]), ...(searchPharmaciesQueryObject.data ?? [])], [searchTerm, pharmacyId, selectedPharmacyQueryObject.data, searchPharmaciesQueryObject.data, pinnedPharmaciesQueryObject.data]);
  return <>
      <FuzzyCombobox allowRemove={allowRemove} currentValue={isNil(pharmacyId) ? null : String(pharmacyId)} onSelect={value => {
      const pharmacy = consolidatedData?.find(item => item.value === value);
      if (pharmacy) {
        onSelectPharmacy && onSelectPharmacy(pharmacy.otherData);
      }
    }} onDeselect={() => {
      onSelectPharmacy && onSelectPharmacy(null);
      setSearchTerm(null);
    }} placeholder={placeholder} containerClassName={containerClassName} popoverTriggerClassName={popoverTriggerClassName} popoverContentClassName={popoverContentClassName} popoverContentItemClassName={cn("hover:!bg-primary !bg-primary", popoverContentItemClassName)} popoverContentSelectedItemClassName={cn("bg-primary", popoverContentSelectedItemClassName)} popoverContentSelectedItemTextClassName={cn("[&>div]:bg-gray-300", popoverContentSelectedItemTextClassName)} popoverContentAlignment={popoverContentAlignment} data={consolidatedData} searchText={searchTerm} setSearchText={setSearchTerm} popoverContentUsePortal={popoverContentUsePortal} dropdownHeight={consolidatedData.filter(v => !v.hide).length === 0 ? null : 400} disabled={disabled} showDisplayValue={showDisplayValue} isDataLoading={selectedPharmacyQueryObject.isLoading || pinnedPharmaciesQueryObject.isLoading} data-sentry-element="FuzzyCombobox" data-sentry-source-file="pharmacyDropdown.tsx" />
    </>;
};
interface PharmacyCardProps {
  pharmacy: Pharmacy | null;
  containerClassName?: ClassValue;
  cardContentClassName?: ClassValue;
  withoutCard?: boolean;
  showPinIconOnPinned?: boolean;
  isLoading?: boolean;
}
export const PharmacyCard = ({
  pharmacy,
  containerClassName,
  cardContentClassName,
  withoutCard = false,
  showPinIconOnPinned = false,
  isLoading
}: PharmacyCardProps) => {
  if (!isNil(isLoading) && isLoading) {
    return withoutCard ? <div className={cn("text-sm", containerClassName)}>
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-3 w-[300px]" />
        <Skeleton className="h-3 w-[200px]" />
        <Skeleton className="h-3 w-[200px]" />
      </div> : <SCNCard className={cn("w-full rounded-md p-4 shadow-sm", containerClassName)}>
        <CardContent className={cn("flex flex-col gap-1.5 p-0 text-component-fg", cardContentClassName)}>
          <Skeleton className="h-4 w-[250px]" />
          <Skeleton className="h-3 w-[300px]" />
          <Skeleton className="h-3 w-[200px]" />
          <Skeleton className="h-3 w-[200px]" />
        </CardContent>
      </SCNCard>;
  }
  if (!pharmacy) {
    return <></>;
  }
  return withoutCard ? <div className={cn("text-sm", containerClassName)} data-sentry-component="PharmacyCard" data-sentry-source-file="pharmacyDropdown.tsx">
      <span className="font-bold">{pharmacy?.name}</span>
      {pharmacy.streetAddress && <div>{pharmacy.streetAddress}</div>}
      {pharmacy.streetAddress2 && <div>{pharmacy.streetAddress2}</div>}
      {pharmacy.city && <div>
          {`${pharmacy.city} `}
          {pharmacy.province && userProvinceMap[pharmacy.province]}
        </div>}
      {pharmacy.postal && <div>{pharmacy.postal?.toUpperCase()}</div>}
      {pharmacy?.phone && <div>{`P: ${pharmacy.phone}`}</div>}
      {pharmacy?.fax && <div>{`F: ${pharmacy.fax}`}</div>}
    </div> : <SCNCard className={cn("w-full rounded-md p-4 shadow-sm", containerClassName)} data-sentry-element="SCNCard" data-sentry-component="PharmacyCard" data-sentry-source-file="pharmacyDropdown.tsx">
      <CardContent className={cn("flex flex-col gap-1.5 p-0 text-component-fg", cardContentClassName)} data-sentry-element="CardContent" data-sentry-source-file="pharmacyDropdown.tsx">
        <div className="flex gap-2">
          {showPinIconOnPinned && pharmacy.pinned && <DrawingPinFilledIcon className="size-5 text-blue-500" />}
          <span className="text-sm font-bold">{pharmacy.name}</span>
        </div>
        <div className="text-sm">{getPharmacyAddress(pharmacy)}</div>
        {pharmacy.phone && <div className="text-sm">{`P: ${pharmacy.phone}`}</div>}
        {pharmacy.fax && <div className="text-sm">{`F: ${pharmacy.fax}`}</div>}
      </CardContent>
    </SCNCard>;
};
interface PharmacyQueryObjectInput {
  pharmacyId?: number | null;
}
export const usePharmacyQueryObject = ({
  pharmacyId
}: PharmacyQueryObjectInput) => {
  const selectedPatient = useAtomValue(selectedPatientAtom);
  return useQuery({
    queryKey: ["pharmacy", {
      patientId: selectedPatient?.id,
      pharmacyId
    }],
    queryFn: () => pharamcyAPI.getPharmacyById(pharmacyId!),
    select: pharmacy => {
      return {
        name: <PharmacyCard pharmacy={pharmacy} />,
        value: String(pharmacy.id),
        displayValue: getPharmacyLabel(pharmacy).label,
        otherData: {
          ...pharmacy,
          itemType: pharmacy.status // I dont know why you would ever do this, but old code does this and if I question it I might not like the answer...
        } satisfies Pharmacy as Pharmacy,
        hide: true
      } satisfies PharmacyComboItem as PharmacyComboItem;
    },
    enabled: !isNil(pharmacyId) && !isNil(selectedPatient?.id),
    staleTime: 0
  });
};