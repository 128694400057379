import userAPI from "@/api/userAPI";
import { useQuery } from "@tanstack/react-query";

const useUserQuery = (id?: number | null) =>
  useQuery({
    queryKey: ["user"],
    queryFn: () => userAPI.getUser(id),
    staleTime: Infinity,
  });

export default useUserQuery;
