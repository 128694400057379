import { atom } from "jotai";
import { EMRPatient } from "@/lib/types/patient";
import { EMRAppointment } from "@/lib/types/appointment";
import { OrderType } from "@/lib/types/encounterNote";
import { AddressBookOrganization, AddressBookOther, AddressBookPhysician } from "@/lib/types/addressBook";
import { Pharmacy } from "@/lib/types/pharmacy";
import { RefObject } from "react";
import { EncounterNotesHandle } from "@/components/encounterNotes/encounterNotes";

export type ClinicalChartTabItems = "patientChart" | "encounter";
export type AppointmentsTabItems = "daySheet" | "calendar";
export type TemplateManagerTabItems = "templateManager" | "componentManager" | "hotPhrasesManager";
export type DocumentsTabItems = "pending" | "inbox" | null;

// TODO: change the default value that is empty objects to null
export const selectedPatientAtom = atom<EMRPatient | null>(null); // key: "selectedPatient"

export const pharmacyListAtom = atom<Pharmacy[]>([]); // key: "pharmacyList"

export const selectedPharmacyAtom = atom<Pharmacy>({} as Pharmacy); // key: "selectedPharmacy"

export const selectedAddressBookPharmacyAtom = atom<Pharmacy>({} as Pharmacy); // key: "selectedAddressBookPharmacy"

export const selectedAppointmentAtom = atom<EMRAppointment>({} as EMRAppointment); // key: "selectedAppointment"

export const encounterNoteIdAtom = atom<number | null>(null); // key: "encounterNoteId"

export const clinicalChartTabAtom = atom<ClinicalChartTabItems>("patientChart"); // key: "clinicalChartTab"

export const templateManagerTabAtom = atom<TemplateManagerTabItems>("templateManager"); // key: "templateManagerTab"

export const documentsTabAtom = atom<DocumentsTabItems>(null); // key: "documentsTab"

interface IUnsavedState {
  unsaved: boolean;
}

export const unsavedStateAtom = atom<IUnsavedState>({ unsaved: false }); // key: "unsavedState"

export const selectedAddressBookOrganizationAtom = atom<AddressBookOrganization>({} as AddressBookOrganization); // key: "selectedAddressBookOrganization"

export const selectedAddressBookOtherAtom = atom<AddressBookOther>({} as AddressBookOther); // key: "selectedAddressBookOther"

export const selectedAddressBookPhysicianAtom = atom<AddressBookPhysician>({} as AddressBookPhysician); // key: "selectedAddressBookPhysician"

export const orderDraftsAtom = atom<OrderType[]>([] as OrderType[]); // key: "orderDrafts"

export const encounterNotesRefAtom = atom<RefObject<EncounterNotesHandle | null> | null>(null);
