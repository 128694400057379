"use client";

import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import { ComponentProps } from "react";
const labelVariants = cva("text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer");
const Label = ({
  className,
  ...props
}: ComponentProps<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>) => <LabelPrimitive.Root className={labelVariants({
  className
})} {...props} data-sentry-element="unknown" data-sentry-component="Label" data-sentry-source-file="label.tsx" />;
Label.displayName = LabelPrimitive.Root.displayName;
export { Label };