"use client";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckIcon, MinusIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps } from "react";
import { cva, VariantProps } from "class-variance-authority";
const checkboxVariants = cva("focus-visible:ring-ring [state=unchecked]:bg-primary data-[state=checked]:text-component-fg data-[state=indeterminate]:text-component-fg group peer flex size-4 shrink-0 items-center justify-center rounded-sm border border-gray-400 bg-primary shadow focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50", {
  variants: {
    variant: {
      default: "",
      blueBg: "data-[state=checked]:border-button-01/80 data-[state=indeterminate]:border-button-01/80 data-[state=checked]:bg-button-01/80 data-[state=indeterminate]:bg-button-01/80 data-[state=checked]:text-gray-200 data-[state=indeterminate]:text-gray-200 disabled:bg-gray-300"
    }
  },
  defaultVariants: {
    variant: "default"
  }
});
export interface CheckboxProps extends ComponentProps<typeof CheckboxPrimitive.Root>, VariantProps<typeof checkboxVariants> {}

// TODO: support the styling classes used here
const Checkbox = ({
  className,
  variant,
  ...props
}: CheckboxProps) => <CheckboxPrimitive.Root className={checkboxVariants({
  variant,
  className
})} {...props} data-sentry-element="unknown" data-sentry-component="Checkbox" data-sentry-source-file="checkbox.tsx">
    <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-current")} data-sentry-element="unknown" data-sentry-source-file="checkbox.tsx">
      <CheckIcon className="inset-0 size-0 group-data-[state=checked]:size-4" data-sentry-element="CheckIcon" data-sentry-source-file="checkbox.tsx" />
      <MinusIcon className="size-0 group-data-[state=indeterminate]:size-4" data-sentry-element="MinusIcon" data-sentry-source-file="checkbox.tsx" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>;
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
export { Checkbox };