import physicianAPIClient from "@/api/PhysicianAPIClient";
import { AddressBookPhysician } from "@/lib/types/addressBook";
import { userProvinceMap } from "@/lib/types/constants";
import { PhysicianLocationModel } from "@/lib/types/physician";
import { AxiosResponse } from "axios";
import { toast } from "sonner";

// TODO: this is copied from legacy dropdown and needs to be refactored completely
export async function getPhysician(id: number): Promise<AddressBookPhysician | undefined> {
  const addressBookPhysicianResponse: AxiosResponse = await physicianAPIClient.getAddressBookPhysician(String(id));
  if (addressBookPhysicianResponse.status !== 200) {
    toast.error("Something went wrong while fetching Physician");
    return Promise.resolve(undefined);
  }
  return Promise.resolve(addressBookPhysicianResponse?.data?.physician);
}

// TODO: these are copied from the legacy dropdown but needs to be refactored and reformatted
export const getPhysicianName = (physician: AddressBookPhysician) => {
  return `${physician?.lastName}, ${physician?.firstName}`;
};

export const getPhysicianLocation = (location: PhysicianLocationModel, twoLineFormat: boolean = false) => {
  return `${location.streetAddress ? `${location.streetAddress}, ` : ""}${twoLineFormat ? "\n" : ", "}${
    location.city ? `${location.city} ` : ""
  }${location.province ? userProvinceMap[location.province] : ""}${location.postal ? `, ${location.postal}` : ""}`;
};

export const getPhysicianLocationPhone = (location: PhysicianLocationModel) => {
  let phoneNumber = location?.phoneNumbers?.find((phone) => phone.preferredPhone === true)?.phoneNumber;

  if (phoneNumber === undefined && location?.phoneNumbers && location?.phoneNumbers.length > 0) {
    phoneNumber = location?.phoneNumbers[0]?.phoneNumber;
  }

  return phoneNumber ? phoneNumber : "";
};

export const getPhysicianLocationFax = (location: PhysicianLocationModel) => {
  let faxNumber = location?.faxNumbers?.find((fax) => fax.preferredFax === true)?.faxNumber;

  if (faxNumber === undefined && location?.faxNumbers && location?.faxNumbers.length > 0) {
    faxNumber = location?.faxNumbers[0]?.faxNumber;
  }

  return faxNumber ? faxNumber : "";
};

export const getPhysicianFax = (physician: AddressBookPhysician) => {
  return physician?.locations &&
    physician?.locations.length > 0 &&
    physician?.locations[0]?.faxNumbers &&
    physician?.locations[0]?.faxNumbers[0]?.faxNumber
    ? physician?.locations[0]?.faxNumbers[0]?.faxNumber
    : null;
};
